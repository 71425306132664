import React, { useState, useEffect } from 'react';
import {
  AgxTextInput,
  AgxCaption,
  AgxInputText,
  AgxColumn,
  AgxRow,
  Images,
  AgxBodyText,
  AgxSelect,
  AgxCurrency,
} from '@urbanx/agx-ui-components';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { parseFloatReturnEmptyIfZero } from 'utils/parseFloatReturnEmptyIfZero';
import './VendorChoiceCommission.scss';

const DOLLAR = '0';
const PERCENTAGE = '1';
const options = [
  { label: 'Dollar', value: DOLLAR },
  { label: 'Percentage', value: PERCENTAGE },
];

const VendorChoiceCommission = ({
  id,
  onValueChanged,
  defaultValue,
  showCommissionRangeDisclaimer,
  validate: validatePage,
}) => {
  const isDesktop = ScreenSize() === Breakpoints.Desktop;
  const isTablet =
    ScreenSize() === Breakpoints.Tablet_Landscape ||
    ScreenSize() === Breakpoints.Tablet_Portrait;
  const [minCommission, setMinCommission] = useState(
    defaultValue?.minCommission
  );
  const [maxCommission, setMaxCommission] = useState(
    defaultValue?.maxCommission
  );
  const [error, setError] = useState(null);
  const [isDollarSelected, setIsDollarSelected] = useState(true);
  const [selectedOption, setSelectedOption] = useState(
    options.find(o => o.value === defaultValue?.valueType) ?? options[0]
  );

  const validate = () => {
    if (Number(minCommission) === 0 && Number(maxCommission) > 0) {
      setError('Min Commission must not be empty');
    } else if (
      Number(minCommission) > 0 &&
      Number(maxCommission) > 0 &&
      Number(maxCommission) <= Number(minCommission)
    ) {
      setError(`Max commission must be greater than Min commission`);
    } else if (Number(maxCommission) === 0 && Number(minCommission)) {
      setError(null);
    } else {
      setError(null);
    }
  };

  const onOptionChange = value => {
    setSelectedOption(options.find(o => o.value === value) ?? options[0]);
    setIsDollarSelected(value === DOLLAR);
    validate();
    if (
      (value === PERCENTAGE && parseFloat(minCommission) > 100) ||
      parseFloat(maxCommission) > 100
    ) {
      resetCommision();
    }
  };

  const resetCommision = () => {
    setMinCommission('');
    setMaxCommission('');
  };

  useEffect(() => {
    validate();
    onValueChanged({
      id,
      value: {
        minCommission: !minCommission ? 0.0 : minCommission,
        maxCommission: !maxCommission ? 0.0 : maxCommission,
        valueType: selectedOption.value,
      },
    });
  }, [minCommission, maxCommission, selectedOption]);

  const renderCurrencyComponents = () => (
    <>
      <AgxCurrency
        id="minCommission"
        noOptionalLabel
        label="Min Commission"
        onInputValueChange={({ value }) => setMinCommission(value)}
        defaultValue={parseFloatReturnEmptyIfZero(minCommission)}
        parentControlValue
        decimalPoints={2}
        displayErrors={
          validatePage && !parseFloatReturnEmptyIfZero(minCommission)
        }
        validate={validatePage && !parseFloatReturnEmptyIfZero(minCommission)}
        hideErrorDescription
        stretch
      />
      <AgxInputText extraClasses="rangeTextStyle">to</AgxInputText>
      <AgxCurrency
        id="maxCommission"
        noOptionalLabel
        label="Max Commission"
        onInputValueChange={({ value }) => setMaxCommission(value)}
        defaultValue={parseFloatReturnEmptyIfZero(maxCommission)}
        displayErrors={
          validatePage && !parseFloatReturnEmptyIfZero(maxCommission)
        }
        hideErrorDescription
        validate={validatePage && !parseFloatReturnEmptyIfZero(maxCommission)}
        parentControlValue
        stretch
      />
    </>
  );

  const renderPercentageComponents = () => (
    <>
      <AgxTextInput
        id="minCommission"
        noOptionalLabel
        label="Min Commission"
        percent
        onInputValueChange={({ value }) => setMinCommission(value)}
        defaultValue={parseFloatReturnEmptyIfZero(minCommission)}
        parentControlValue
        error={validatePage && !parseFloatReturnEmptyIfZero(minCommission)}
        hideErrorDescription
        stretch
      />
      <AgxInputText extraClasses="rangeTextStyle">to</AgxInputText>
      <AgxTextInput
        id="maxCommission"
        noOptionalLabel
        label="Max Commission"
        percent
        onInputValueChange={({ value }) => setMaxCommission(value)}
        defaultValue={parseFloatReturnEmptyIfZero(maxCommission)}
        error={validatePage && !parseFloatReturnEmptyIfZero(maxCommission)}
        parentControlValue
        hideErrorDescription
        stretch
      />
    </>
  );

  return (
    <>
      <AgxColumn fill largeGap>
        <AgxColumn fill smallGap>
          {!isDesktop && !isTablet && (
            <AgxSelect
              options={options}
              id={`select-${id}`}
              defaultValue={{ label: 'Dollar', value: DOLLAR }}
              onValueChanged={({ value }) => onOptionChange(value)}
              label="Dollar/Percentage"
              required
              stretch
              small
            />
          )}
          <AgxRow largeGap>
            {(isDesktop || isTablet) && (
              <AgxSelect
                options={options}
                id={`select-${id}`}
                defaultValue={selectedOption}
                onValueChanged={({ value }) => onOptionChange(value)}
                label="Dollar/Percentage"
                required
                small
              />
            )}
            {isDollarSelected && renderCurrencyComponents()}
            {!isDollarSelected && renderPercentageComponents()}
          </AgxRow>
          {error && (
            <AgxRow fill mediumGap>
              <Images.AlertCircle />
              <AgxBodyText small extraClasses="error">
                {error}
              </AgxBodyText>
            </AgxRow>
          )}
        </AgxColumn>
        {showCommissionRangeDisclaimer ? (
          <AgxCaption>
            Between {isDollarSelected ? '$' : ''}
            {Number(minCommission) || 0}
            {!isDollarSelected ? '%' : ''} and {isDollarSelected ? '$' : ''}
            {Number(maxCommission) || 0}
            {!isDollarSelected ? '%' : ''} of the selling price to be determined
            by the Principal
          </AgxCaption>
        ) : (
          <AgxCaption>
            A fee range of between {isDollarSelected ? '$' : ''}
            {Number(minCommission) || 0}
            {!isDollarSelected ? '%' : ''} and {isDollarSelected ? '$' : ''}
            {Number(maxCommission) || 0}
            {!isDollarSelected ? '%' : ''} inclusive of GST of the actual sale
            price to be determined by the client once the sale contract has
            become unconditional. It is to be based on your level of
            satisfaction with the level of service experience and result we
            achieve.
          </AgxCaption>
        )}
      </AgxColumn>
    </>
  );
};

export default VendorChoiceCommission;
