import { useEffect, useMemo, useState } from 'react';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  Images,
  AgxBodyText,
  AgxSlideUpModal,
  AgxMultiOptionButton,
  DocumentTypesMap,
  DocumentTypes,
  VendorBuyer,
  StakeholderType,
  FormType,
  DocumentTypesOrderMap,
  SendConfigurations,
  RenderAnimation,
  RenderAnimationType,
  AustralianState,
} from '@urbanx/agx-ui-components';
import { isEqual } from 'lodash';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { useNavigate } from 'react-router-dom';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useFormClearer } from 'hooks/useFormCleaner';
import { useFormSettings } from 'hooks/useFormSettings';
import usePropertyImageSelector from 'selectors/usePropertyImageSelector';
import useFormDocumentsSelector from 'selectors/useFormDocumentsSelector';
import useFormId from 'hooks/useFormId';
import useFormCampaignId from 'hooks/useFormCampaignId';
import ErrorModal from 'helpers/ErrorModal';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { markCampaignsAsStale } from '../../campaigns/campaignsReducer';
import { completeCampaignStep, sendForm } from 'Api/Campaigns/campaignsApi';
import { loadFormConfig } from '../../form/formReducer';
import { setAndShowErrorToast } from 'store/config';
import { PreparedSignatory, prepareSignatories } from 'utils/vendorBuyerUtil';
import placeHolderImage from 'assets/images/placeholder-property.png';
import SendConfirmation from './SendConfirmation';
import { MultiOptionButtonOption } from '@urbanx/agx-ui-components/dist/cjs/types/components/actions/MultiOptionButton/MultiOptionButton';
import './ContractReviewAndSend.scss';
import { openFileInNewTab } from 'utils/openFileInNewTab';

interface ContractReviewAndSignProps {
  goToFirstPage: () => void;
  goToPreviousPage: () => void;
  vendors: VendorBuyer[];
  formName: string;
  sendViaDocuSign: boolean;
  disableFormSubmission?: boolean;
  sendConfigurations: SendConfigurations[];
}

const ContractReviewAndSign = (props: ContractReviewAndSignProps) => {
  const {
    goToFirstPage,
    goToPreviousPage,
    vendors,
    formName,
    sendViaDocuSign,
    disableFormSubmission,
    sendConfigurations,
  } = props;
  const updateFormSettings = useFormSettings();
  const formId = useFormId();

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [buyerSignatories, setBuyerSignatories] = useState<PreparedSignatory[]>(
    []
  );
  const [vendorSignatories, setVendorSignatories] =
    useState<PreparedSignatory[]>();
  const [showDocuSignLoader, setShowDocuSignLoader] = useState(false);
  const [showSuccessLoader, setShowSuccessLoader] = useState(false);
  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const isDesktop = ScreenSize() === Breakpoints.Desktop;
  const errors = useAppSelector(state => state.form.formErrors);
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const { medium } = usePropertyImageSelector() || {};
  const documents = useFormDocumentsSelector();
  const getFileLink = useGetFileLink();
  const [, getAuthToken] = useAzureAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clearFormData = useFormClearer();
  const [showRecipients, setShowRecipients] = useState(false);
  const [configuration, setConfiguration] = useState(sendConfigurations[0]);
  const [disableButtons, setDisableButtons] = useState(false);
  const [buyerSolicitorRecipient, setBuyerSolicitorRecipient] =
    useState<PreparedSignatory>();
  const [vendorSolicitorRecipient, setVendorSolicitorRecipient] =
    useState<PreparedSignatory>();

  const {
    selectedForm,
    formValues: {
      Buyers: campaignBuyers,
      BuyerSolicitor: buyerSolicitor,
      VendorSolicitor: vendorSolicitor,
    },
  } = useAppSelector(state => state.form);
  const campaignId = useFormCampaignId();

  const {
    agents: { loadingState: agentsLoaded, items: agents },
  } = useAppSelector(state => state.agencies);

  const noAttachments = useMemo(() => {
    const documentTypes = documents.map(doc => doc.documentType);
    const uniqueTypes = new Set(documentTypes);
    // Check if the unique types are one 'AgencyAgreement' and one 'MergedAgencyAgreement'
    return (
      uniqueTypes.size === 2 &&
      uniqueTypes.has(DocumentTypes.MergedSalesContract)
    );
  }, [documents]);

  const fileDownloadOptions = useMemo(() => {
    if (documents.length === 0) return [];
    const options: MultiOptionButtonOption[] = [];
    const miscDocumentTypes = [
      DocumentTypes.Miscellaneous,
      DocumentTypes.AdditionalAnnexure,
    ];

    const orderedDocuments = [...documents].sort((a, b) => {
      return (
        DocumentTypesOrderMap[a.documentType] -
        DocumentTypesOrderMap[b.documentType]
      );
    });

    const nonMiscDocuments = orderedDocuments.filter(
      (d, ind, arr) =>
        !miscDocumentTypes.includes(d.documentType) &&
        (!ind || d.documentType !== arr[ind - 1]?.documentType)
    );
    const miscDocuments = orderedDocuments.filter(d =>
      miscDocumentTypes.includes(d.documentType)
    );

    nonMiscDocuments.forEach(d => {
      options.push({
        text: DocumentTypesMap[d.documentType],
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    miscDocuments.forEach(d => {
      options.push({
        text: d.fileName,
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });

    return options;
  }, [documents, getFileLink]);

  useEffect(() => {
    updateFormSettings({
      formBackgroundInverted: showDocuSignLoader || showSuccessLoader,
      growContent: showDocuSignLoader || showSuccessLoader,
    });
  }, [showDocuSignLoader, showSuccessLoader]);

  const beginAgencyAgreementSubmission = async () => {
    if (!getAuthToken || !campaignId || !formId) return;

    const contractSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ContractSubmission &&
        isEqual(form.state, selectedForm?.state)
    );

    if (contractSubmissionForm == null) {
      return;
    }

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        true,
        formId
      );

      if (!newForm) {
        dispatch(setAndShowErrorToast('Failed to start Contract submission'));
        setDisableButtons(false);
        return;
      }

      dispatch(loadFormConfig(newForm));
    } catch (err) {
      dispatch(setAndShowErrorToast('Failed to start Contract submission'));
    }

    setDisableButtons(false);
  };

  const sendEnvelope = async (emailVendorNow: boolean) => {
    if (!getAuthToken || !campaignId) return;

    try {
      setShowDocuSignLoader(true);
      updateFormSettings({
        showBreadcrumbs: false,
        displayTitleVisible: false,
      });

      const authToken = await getAuthToken();

      if (!authToken) {
        updateFormSettings({
          showBreadcrumbs: true,
          displayTitleVisible: true,
        });
        setShowDocuSignLoader(false);
        return;
      }

      await sendForm(authToken, {
        campaignId,
        formId: formId || '',
        formType: selectedForm?.formType as FormType,
        state: selectedForm?.state as AustralianState,
        digitalSignatureSubmission: {
          sendVendorsNow: emailVendorNow,
        },
      });

      setTimeout(() => {
        setShowDocuSignLoader(false);
        setShowSuccessLoader(true);
        setTimeout(() => {
          dispatch(markCampaignsAsStale());
          clearFormData();
          navigate('/');
        }, 1800);
      }, 1500);
    } catch (err: any) {
      updateFormSettings({
        showBreadcrumbs: true,
        displayTitleVisible: true,
      });
      setShowDocuSignLoader(false);
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
    }
  };

  useEffect(() => {
    if (vendors) {
      setVendorSignatories(prepareSignatories(vendors, StakeholderType.Vendor));
    }
  }, [agents, agentsLoaded, vendors]);

  useEffect(() => {
    if (campaignBuyers) {
      setBuyerSignatories(
        prepareSignatories(campaignBuyers, StakeholderType.Buyer)
      );
    }
  }, [campaignBuyers]);

  useEffect(() => {
    if (buyerSolicitor) {
      setBuyerSolicitorRecipient({
        id: buyerSolicitor.solicitorName,
        name: buyerSolicitor.solicitorName,
        email: buyerSolicitor.solicitorEmail,
        type: StakeholderType.BuyerSolicitor,
      });
    }
  }, [buyerSolicitor]);

  useEffect(() => {
    if (vendorSolicitor) {
      setVendorSolicitorRecipient({
        id: vendorSolicitor?.solicitorName,
        name: vendorSolicitor?.solicitorName,
        email: vendorSolicitor.solicitorEmail,
        type: StakeholderType.VendorSolicitor,
      });
    }
  }, [vendorSolicitor]);

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}>Starting a submission?</AgxHeader>
      <AgxBodyText medium>
        You will not be able to edit the Contract once you continue.
      </AgxBodyText>
      <AgxBodyText medium>Continue to submission?</AgxBodyText>
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          id="startsubmission"
          large
          primary
          text="Yes, start submission"
          rightIcon={<Images.ArrowForwardOutline />}
          onClick={beginAgencyAgreementSubmission}
          disabled={disableButtons}
        />
        <AgxButton
          id="cancel"
          hollow
          large
          text="No, go back"
          rightIcon={<Images.CloseOutline />}
          onClick={() => setShowConfirmationModel(false)}
          disabled={disableButtons}
        />
      </div>
    </AgxColumn>
  );

  const viewPdfButton = () => {
    if (fileDownloadOptions.length === 0) return;

    if (fileDownloadOptions.length === 1 || noAttachments) {
      return (
        <AgxButton
          text="Download"
          hollow
          medium
          rightIcon={<Images.Download />}
          wide={isMobile}
          onClick={fileDownloadOptions[0].onClick}
        />
      );
    } else {
      return (
        <AgxMultiOptionButton
          id="viewContractPdfs"
          text="Download"
          hollow
          medium
          rightIcon={<Images.Download />}
          wide={isMobile}
          options={fileDownloadOptions}
        />
      );
    }
  };

  const errorInfo = (
    <AgxRow centered veryLargeGap extraClasses={'toastStyle'} fill={isMobile}>
      <span>
        <Images.AlertCircle />
      </span>
      <AgxBodyText>
        {`Please complete required fields before ${sendViaDocuSign ? 'DocuSigning' : 'sending'}`}
        <br />
        <AgxButton
          text="View Details"
          naked
          extraClasses={'hyperlink'}
          onClick={() => setOpenErrorModal(true)}
        ></AgxButton>
      </AgxBodyText>
    </AgxRow>
  );

  const onPreviousClicked = () => {
    goToPreviousPage();
  };

  if (showDocuSignLoader)
    return (
      <AgxColumn
        fill
        centerJustified
        centered
        extraClasses="sendForSigningStatusContent"
      >
        <RenderAnimation icon={RenderAnimationType.Paper} />
        <AgxColumn fill centered largeGap>
          <AgxHeader size={1} inverse>
            Just a sec
          </AgxHeader>
          <AgxHeader size={3} secondary>
            {`Sending ${formName}...`}
          </AgxHeader>
        </AgxColumn>
      </AgxColumn>
    );

  if (showSuccessLoader)
    return (
      <AgxColumn
        fill
        centerJustified
        centered
        extraClasses="sendForSigningStatusContent"
      >
        <RenderAnimation icon={RenderAnimationType.Champagne} />
        <AgxHeader size={1} inverse>
          Success!
        </AgxHeader>
      </AgxColumn>
    );

  if (showRecipients)
    return (
      <SendConfirmation
        buyers={buyerSignatories}
        buyerSolicitor={buyerSolicitorRecipient}
        vendors={vendorSignatories}
        vendorSolicitor={vendorSolicitorRecipient}
        onBack={() => setShowRecipients(false)}
        onSend={(sendVendorsNow: boolean) => sendEnvelope(sendVendorsNow)}
        sendViaDocuSign={sendViaDocuSign}
        sendConfiguration={configuration}
        isMobile={isMobile}
      />
    );

  return (
    <AgxColumn extraClasses="reviewAndSignImageCard">
      <AgxColumn
        veryLargeGap
        extraLargePadding
        centered
        extraClasses="agreementDetails"
      >
        <AgxHeader
          size={sendViaDocuSign ? 4 : 3}
          centered
        >{`Review ${formName} before proceeding:`}</AgxHeader>
        {sendViaDocuSign ? (
          <img
            className="propertyImage"
            alt="Avatar"
            src={medium || placeHolderImage}
          />
        ) : (
          <Images.SparkleForm />
        )}
        <AgxRow largeGap centered justifyCenter={isMobile} fill={isMobile}>
          {viewPdfButton()}
          <AgxButton
            text="Edit"
            onClick={goToFirstPage}
            hollow
            medium
            rightIcon={<Images.EditOutline />}
            disabled={disableButtons}
          />
        </AgxRow>
      </AgxColumn>
      {sendViaDocuSign || sendConfigurations?.length > 1 ? (
        <div className="signingGrid">
          <AgxColumn largeGap centered extraClasses="signingGridCell">
            <AgxLabel large>DocuSign</AgxLabel>
            <AgxButton
              primary
              large
              rightIcon={<Images.Person />}
              text={sendConfigurations[0]?.title}
              onClick={() => {
                if (errors.length > 0) {
                  setOpenErrorModal(true);
                } else {
                  setShowRecipients(true);
                  setConfiguration(sendConfigurations[0]);
                }
              }}
              disabled={disableButtons}
              wide
            />
            <AgxButton
              primary
              large
              rightIcon={<Images.People />}
              text={sendConfigurations[1]?.title}
              onClick={() => {
                if (errors.length > 0) {
                  setOpenErrorModal(true);
                } else {
                  setShowRecipients(true);
                  setConfiguration(sendConfigurations[1]);
                }
              }}
              disabled={disableButtons}
              wide
            />
            {errors.length > 0 && errorInfo}
          </AgxColumn>
          {!disableFormSubmission && (
            <AgxColumn largeGap centered extraClasses="signingGridCell">
              <AgxLabel large>Sign in person</AgxLabel>
              <AgxButton
                primary
                large
                rightIcon={<Images.EditOutline />}
                text="Download to Sign"
                onClick={
                  fileDownloadOptions.length > 0
                    ? fileDownloadOptions[0].onClick
                    : undefined
                }
                wide
                disabled={!fileDownloadOptions.length}
              />
              {fileDownloadOptions.length > 0 && (
                <AgxButton
                  hollow
                  large
                  rightIcon={<Images.ArrowForwardOutline />}
                  text="Submit Signed PDF"
                  onClick={() => setShowConfirmationModel(true)}
                  disabled={disableButtons}
                  wide
                />
              )}
            </AgxColumn>
          )}
        </div>
      ) : (
        <>
          {errors.length > 0 && (
            <AgxColumn extraClasses={'alignColumn'}>{errorInfo}</AgxColumn>
          )}
          <div className="emailGrid borderTop">
            <AgxColumn centered>
              <AgxRow largeGap fill justifyCenter>
                <AgxButton
                  hollow
                  large
                  text="Back"
                  onClick={onPreviousClicked}
                  leftIcon={<Images.Vector />}
                />
                <AgxButton
                  primary
                  large
                  text="Next"
                  onClick={() => {
                    setShowRecipients(true);
                    setConfiguration(sendConfigurations[0]);
                  }}
                  rightIcon={<Images.ArrowForwardOutline />}
                  disabled={errors.length > 0}
                />
              </AgxRow>
            </AgxColumn>
          </div>
        </>
      )}

      {openErrorModal && (
        <ErrorModal errorList={errors} setShowModal={setOpenErrorModal} />
      )}

      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </AgxColumn>
  );
};

export default ContractReviewAndSign;
